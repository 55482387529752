import type { NextPage } from 'next';
import Head from 'next/head';
import { useSearchParams } from 'next/navigation';
import Logo from '@/assets/bits-login-logo.svg';
import { LargeButton } from '@bits/ui';
import { signIn, SignInAuthorizationParams } from 'next-auth/react';
import { useTranslation } from 'react-i18next';

const Home: NextPage = () => {
  const searchParams = useSearchParams();
  const error = searchParams?.get('error');

  const authenticate = (params?: SignInAuthorizationParams) =>
    signIn(
      'auth0',
      {
        redirect: false,
        callbackUrl: searchParams?.get('from') || '/applications/individuals',
      },
      params
    );

  const onSignIn = () => authenticate();

  return (
    <>
      <Head>
        <title>Bits Technology: Dashboard</title>
      </Head>
      <main className="bg-sand-light flex min-h-screen flex-col justify-center">
        <div className="shadow-soft mx-auto flex w-full max-w-md flex-col justify-center rounded-[10px] bg-white p-8 pb-11">
          <div className="flex justify-between">
            <h3 className="bits-text-h1">Login</h3>
            <Logo className="w-[155px]" />
          </div>
          <div className="mt-8">
            <LargeButton className="w-full" onClick={onSignIn}>
              Login
            </LargeButton>
          </div>
          {error && <LoginError error={error} />}
        </div>
      </main>
    </>
  );
};

const LoginError = ({ error }: { error: string }) => {
  const { t } = useTranslation('login');
  return (
    <div className="mt-4">
      <p className="text-sm">
        {t(`error.${error}`, { defaultValue: 'Unknown error' })}
      </p>
    </div>
  );
};

export default Home;
